<template>
  <section class="container-fluid parceiros__container">
    <v-row class="d-flex align-center">
      <div class="d-flex justify-start container">
        <Breadcrumb class="projeto_section_bc-color">
          <template v-slot:breadcrumbLinks>
            <router-link
              tag="p"
              :to="{ name: link.href }"
              v-for="(link, idx) in breadcrumbItems"
              :key="idx"
            >
              {{ link.text }}
            </router-link>
          </template>
        </Breadcrumb>
      </div>
    </v-row>
    <v-row class="d-flex justify-center a_sirene_image_title">
      <img src="../assets/projeto_card_sirene.png" />
    </v-row>
    <v-row class="d-flex justify-center align-center">
      <v-col cols="12" class="d-flex justify-center align-center">
        <div
          class="container d-flex justify-center align-center flex-column"
          style="background-color: white;"
        >
          <div
            class="container d-flex justify-md-center align-md-center justify-end align-end flex-column sirene__titulo"
          >
            <img
              width="70%"
              class="mt-4 d-flex justify-center align-center"
              src="../assets/projeto_a_sirene_titulo.png"
            />
            <div class="sirene__titulo_sidebox">
              <p>Edição Especial 2022</p>
            </div>
          </div>

          <div class="threeColumns">
            <p>
              O Jornal A Sirene foi lançado em fevereiro de 2016 com o intuito
              de denunciar as violações de direitos sofridas pelas pessoas
              atingidas pelo crime da Samarco, Vale e BHP, assim como preservar
              a memória das comunidades atingidas. O nome do periódico remete à
              sirene que deveria ter sido acionada com o rompimento da barragem
              de Fundão, em Mariana, mas que não soou. A necessidade de ter um
              espaço de comunicação próprio surgiu após o desgaste com a forma
              como a cobertura do rompimento da barragem de Fundão, em novembro
              de 2015, foi conduzida pela mídia comercial. As pessoas atingidas
              e outros atores da sociedade civil se sentiram incomodadas com o
              sensacionalismo explorado, falas distorcidas e aproximação
              econômica entre mineradoras e a mídia comercial. Dessa forma,
              desde 2016, pessoas atingidas junto a jornalistas participam de
              toda cadeia produtiva do Jornal A Sirene, sendo uma experiência
              singular de comunicação popular. Com uma nova edição lançada
              sempre no dia 5 de cada mês, o periódico veicula relatos sobre as
              dificuldades enfrentadas pelas pessoas atingidas e denúncias sobre
              a atuação da Samarco, Vale, BHP e Fundação Renova, responsáveis
              pela reparação das perdas e danos causados pelo crime-desastre na
              bacia do rio Doce. O jornal traz também esclarecimentos para as
              comunidades atingidas sobre direitos e questões relacionadas à
              reparação integral, além de ser um meio para a troca de
              experiências entre os antigos vizinhos. Garantir o direito à
              comunicação, entendido aqui como um direito humano universal, e o
              acesso à informação de qualidade é o que motiva a produção do
              Jornal A Sirene.
            </p>
            <p>
              A publicação é um meio para expressar opiniões e difundir
              informações relevantes de forma completamente independente. A
              comunicação é fundamental para a reconstrução dos modos de vida e
              para a luta pelos demais direitos das pessoas atingidas.
            </p>
            <div>
              <img src="../assets/projeto_sirene_01.png" alt="" />
              <p style="font-size: 14px; text-align: center;">
                Foto: Lucas de Godoy / Jornal A Sirene.
              </p>
            </div>

            <p>
              O Jornal A Sirene materializa o direito à comunicação. Em sua
              edição especial de seis anos, por exemplo, o veículo de
              comunicação trouxe matérias que retomou a discussão sobre o seu
              papel desempenhado, desde 2016. As publicações mensais são
              distribuídas gratuitamente. Desde o início da pandemia de
              Covid-19, o jornal passou a ter apenas a sua versão digital, uma
              medida de segurança já que as edições eram entregues nas casas das
              pessoas atingidas. É possível acompanhar as matérias no site do
              jornal. Todas as edições lançadas estão disponíveis na plataforma
              ISSUU.
            </p>

            <div>
              <img src="../assets/projeto_sirene_02.png" alt="" />
              <p style="font-size: 14px; text-align: center;">
                Capa da edição zero do Jornal A Sirene, de fevereiro de 2016.
              </p>
            </div>

            <p>
              O Jornal A Sirene é feito pelas pessoas atingidas, para as pessoas
              atingidas e para todos e todas que lutam por justiça. Apoie o
              jornal A Sirene, assine a campanha de financiamento coletivo. Se
              preferir, faça uma doação pelo pix: 33.417.899/0001-00. O jornal,
              conta com o apoio de algumas organizações, são elas: Cáritas
              regional Minas Gerais, Arquidiocese de Mariana, Ministério Público
              de Minas Gerais (MPMG), Movimento dos Atingidos por Barragens
              (MAB), Associação Estadual de Defesa Ambiental e Social (AEDAS) e
              Wiki Rio Doce.
            </p>

            <p style="font-size: 15px; text-align: right;">
              <strong
                >Autores: Wan Campos <sup>1</sup>, Wigde Arcangelo
                <sup>2</sup></strong
              >
            </p>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-md-5">
      <v-col
        cols="12"
        class="container d-flex flex-column justify-center align-center"
      >
        <div class="inside__card container">
          <p style="font-size: 0.5em;">
            <sup>1</sup> Jornalismo (UFOP), Comunicador Popular da ATI Mariana,
            desde 2021.
          </p>
          <p style="font-size: 0.5em;">
            <sup>2</sup> Jornalismo (UFOP), Comunicador Popular da Cáritas MG,
            desde 2021.
          </p>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Parceiros /",
          href: "projeto_parceiros",
        },
        {
          text: "CABF /",
          href: "projeto_cabf",
        },
        {
          text: "A Sirene",
          href: "projeto_sirene",
        },
      ],
    };
  },
};
</script>

<style scoped>
.threeColumns {
  -webkit-column-count: 3;
  -webkit-column-gap: 30px;
  -moz-column-count: 3;
  -moz-column-gap: 30px;
  column-count: 3;
  column-gap: 30px;

  text-align: justify;

  background-color: white;
  padding: 1.5em;
  font-size: 1em;
}

.sirene__titulo {
  position: relative;
}

.sirene__titulo_sidebox {
  position: absolute;
  right: 10px;
  top: 1em;
  border: 2px solid black;
  text-align: center;
  max-width: 6em;
}

.sirene__titulo_sidebox p {
  font-size: 0.3em;
  margin: 0;
  padding: 10px;
  white-space: nowrap;
}

.sirene__titulo:before {
  position: absolute;
  top: 10px;
  content: "";
  width: 100%;
  height: 6px;
  background-color: black;
}

.sirene__titulo:after {
  position: absolute;
  bottom: -10px;
  content: "";
  width: 100%;
  height: 6px;
  background-color: black;
}

@media screen and (max-width: 600px) {
  .threeColumns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .border__image {
    width: 100%;
  }

  .sirene__titulo_sidebox {
    position: relative;
    right: 0;
    top: 0.5em;
  }

  .sirene__titulo_sidebox p {
    font-size: 8px;
    width: 100%;
    text-align: center;
  }
  .sirene__titulo img {
    width: 100%;
  }
}

.threeColumns p {
  margin-top: 0px;
}

.parceiros__container {
  background-image: url("../assets/projeto_parceiros_background_01.png");
  background-size: cover;
  background-position: center center;
  height: 100%;
}

section {
  background-color: #f9f7ed;
  padding-top: 2em;
  padding-bottom: 2em;
  margin: 0;
}

.creators__box {
  max-width: 40%;
}
.creators__box p {
  text-align: center;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 2em;
}

.inside__card p {
  font-size: 1em;
  margin-bottom: 0;
}

.border__image {
  width: 70%;
  padding: 2em;
  position: relative;
}

.border__image p {
  font-size: 14px;
}
.border__image h3 {
  color: #e06919;
}

.a_sirene_image_title img {
  width: 30%;
}

@media screen and (max-width: 600px) {
  .a_sirene_image_title img {
    width: 60%;
  }
}
</style>
